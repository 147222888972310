* {
    box-sizing: border-box;
    margin: 0;
}

html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Urbanist', sans-serif;
    background: #14141f;
}

a {
    display: inline-block;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    color: #FFFFFF;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

img {
    max-width: 100%;
    height: auto;
}

h1 {
    font-weight: 700;
    font-size: 56px;
    line-height: 1.2;
}

h1 span {
    color: #37A9FF;
}

h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.25;
    text-transform: capitalize;
}

.container {
    padding: 0 20px;
}
.text-right{
    text-align: right;
}
.common-btn {
    border: 1px solid #37A9FF;
    border-radius: 24px;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.2s;
    padding: 11px 30px;
    cursor: pointer;
}

.common-btn.border-white {
    border: 1px solid #fff;
    background-color: transparent;
}

.common-btn.border-white:hover {
    border: 1px solid #37A9FF !important;
}

.common-btn.white {
    background: #fff;
    border: 0;
    color: #14141F;
}

.common-btn.white:hover {
    color: #fff;
}

.common-btn.white img.white {
    display: none;
}

.common-btn.white:hover img {
    display: none;
}

.common-btn.white:hover img.white {
    display: inline-block;
}

.common-btn img {
    margin-right: 8px;
}

.common-btn:hover {
    background: #37A9FF;
}

.gradient-line {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    padding-bottom: 2px;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}

.gradient-line:hover {
    opacity: 0.9;
}

.gradient-line:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    background: #37A9FF;
    height: 1px;
}

.main-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
}

.main-head .gradient-line {
    margin-bottom: 9px;
}

/* Header */

header {
    padding: 16px 0;
    border-bottom: 1px solid #8A8AA0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

header nav {
    padding: 0 !important;
}

header .navbar-brand {
    font-weight: 700;
    font-size: 36px;
    text-transform: capitalize;
    color: #FFFFFF !important;
    display: flex;
    align-items: flex-end;
    padding: 0;
    line-height: 1;
}

/* header .navbar-brand img {
    width: 140px;
} */

header .navbar-collapse {
    justify-content: center;
}

header .navbar-nav li {
    margin: 0 20px;
}

header .navbar-nav li a {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    transition: all ease-in-out 0.2s;
}

header .navbar-nav li a.nav-link {
    padding: 0 !important;
}

header .navbar-nav li a:hover,
header .navbar-nav li .nav-link.show {
    color: #37A9FF;
}

header .dropdown-toggle {
    padding: 0;
}

header .dropdown-menu {
    background-color: #343444;
    top: 45px !important;
}

header .dropdown-menu li {
    margin: 0;
}

header .dropdown-menu li a {
    display: block;
    padding: 5px 15px;
    font-weight: 500;
    font-size: 16px;
}

header .dropdown-menu li a:hover {
    background: #37A9FF;
    color: #fff;
}

header .dropdown-toggle:after {
    background-image: url(../../public/images/arrow-down.png);
    background-size: 20px;
    border: 0;
    width: 20px;
    height: 20px;
    vertical-align: sub;
}

header .navbar-toggler {
    padding: 0;
    border: 0;
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
}

.navbar-toggler-bar {
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 10px;
    display: block;
}

header .right {
    margin-left: auto;
    display: flex;
}

header .right select {
    height: 46px;
    background-color: #14141F !important;
    padding: 11px 35px 11px 20px !important;
    margin-right: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #37A9FF !important;
    background-image: url(../../public/images/arrow-down.png) !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 8px) !important;
    outline: 0;
}

/* Header End */


/* Banner */
.top-wrap {
    background-image: url(../../public/images/top-bg-img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.top-wrap:before, .mid-bg-wrap:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.75);
}

.top-wrap + .top-seller{
    padding-top: 80px;
}

.mid-bg-wrap{
    background-image: url(../../public/images/mid-bg-img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.banner {
    padding: 104px 0 90px;
    position: relative;
}

.banner h1 {
    max-width: 520px;
    margin-bottom: 30px;
}

.banner.sell h1 span {
    display: block;
}

.banner p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #EBEBEB;
    margin-bottom: 36px;
}

.banner .common-btn {
    min-width: 142px;
    margin-right: 16px;
}

.banner .common-btn:last-child {
    margin-right: 0;
}

.banner .common-btn img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.banner .row {
    align-items: center;
}


.banner-inner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-image: url('../../public/images/banner-inner-bg.png');
    text-align: center;
    min-height: 300px;
    padding: 140px 0 60px;
}

.banner-inner:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(20, 20, 31, 0.70);
}

.banner-inner+.picks,
.banner-inner+.profile {
    margin-top: 80px;
}

.banner-inner .container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.banner-inner h1 {
    font-size: 48px;
    text-transform: capitalize;
    margin-bottom: 12px;
}

.banner-inner .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
}

.banner-inner .breadcrumb-item {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
}

.banner-inner .breadcrumb-item a {
    color: #8A8AA0;
    font-size: 18px;
    line-height: 28px;
}

.banner-inner .breadcrumb-item:before {
    font-size: 18px;
    line-height: 28px;
    color: #8A8AA0;
}

/* Banner */


.picks {
    padding-bottom: 80px;
    position: relative;
}

.picks.selling .collection-box .content .bid-price {
    padding-top: 0;
    border-top: 0;
}

.picks.selling .collection-box .content .creator-wrap .bid-price {
    margin-top: 0;
}

.picks.selling .collection-box .content .creator-wrap .bid-price .left {
    flex-direction: column;
}

.picks.selling .common-btn {
    padding: 9px 25px;
    color: #fff !important;
    font-size: 14px !important;
    min-width: 141px;
}

.picks.selling .common-btn:hover {
    color: #14141F !important;
    background: #fff !important;
}

.picks.selling .common-btn img {
    display: none;
}

.picks.selling .common-btn img.white {
    display: inline-block;
}

.picks.selling .common-btn:hover img {
    display: inline-block;
}

.picks.selling .common-btn:hover img.white {
    display: none;
}

.collection-box .content .collection-content-head {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}

.collection-box .content .collection-content-head h3 {
    width: calc(100% - 55px);
    margin-bottom: 0;
}


/* Blog */
.blogs-wrapper {
    padding: 80px 0;
}

.blogs-wrapper .collection-box .content .creator-wrap {
    margin-bottom: 16px;
}

.blogs-wrapper .collection-box .content .creator-wrap .date {
    color: #FFF;
    font-size: 18px;
    line-height: 28px;
}

.blogs-wrapper .collection-box .content h3 {
    font-size: 24px;
    line-height: 30px;
}

.blogs-wrapper .collection-box .content p {
    margin-bottom: 24px;
}

.blogs-wrapper .collection-box .img-wrap>img {
    height: 280px;
}

/* Services */


/* Services End*/

.services {
    padding-bottom: 30px;
    position: relative;
}

.service-box {
    margin-bottom: 50px;
}

.service-box img {
    margin-bottom: 24px;
}

.service-box h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.service-box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
}


/* Auction */

.auctions {
    padding: 60px 0 68px;
    background: #0D0D11;
    overflow: hidden;
    position: relative;
}

.auctions.sell {
    background: transparent;
    padding: 0;
    padding-bottom: 30px;
}

.auctions .collection-box {
    margin: 0 15px;
}

.auctions .auction-slider-wrapper {
    margin: 0 -120px;
}

.auctions.sell .auction-slider-wrapper {
    margin: 0 -15px;
}


.auctions .slick-dots {
    position: static;
    display: flex !important;
    justify-content: center;
    margin-top: 32px;
}

.auctions .slick-dots li {
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auctions .slick-dots li {
    width: 18px;
    height: 18px;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.2s;
}

.auctions .slick-dots li.slick-active {
    border: 1px solid #fff;
}

.auctions .slick-dots li button {
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    padding: 0;
    transition: all ease-in-out 0.2s;
}

.auctions .slick-dots li.slick-active button,
.auctions .slick-dots li:hover button {
    background: #fff;
}

.auctions .slick-dots li button:before {
    font-size: 0;
    width: 8px;
    height: 8px;
}

/* Auction End */


/* Popular Collection */

.popular-collection {
    margin-bottom: 56px;
    position: relative;
}

.mid-bg-wrap > *:first-child{
    padding-top: 80px;
}

.collection-box .images-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.collection-box .images-wrapper img {
    display: flex;
    flex-wrap: wrap;
    width: calc(33.33% - 10px);
    margin: 5px;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
    height: 131px;
}

.collection-box .images-wrapper>img:first-child,
.collection-box .images-wrapper>img:nth-child(2) {
    width: calc(50% - 10px);
}

.popular-collection .collection-box .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popular-collection .collection-box .content .left {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 75px);
}

.profile .popular-collection .collection-box .content .left {
    width: 100%;
}

.popular-collection .collection-box .content .img-wrap {
    position: relative;
}

.popular-collection .collection-box .content .img-wrap img {
    width: 64px;
    height: 64px;
    border-radius: 20px;
}

.popular-collection .collection-box .content .img-wrap img.top-seller-mark {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.popular-collection .collection-box .content .info {
    padding-left: 10px;
    width: calc(100% - 64px);
}

.popular-collection .collection-box .content .info h3 {
    font-size: 20px;
    margin-bottom: 2px;
}

.popular-collection .collection-box .content .info p {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #EBEBEB;
}

.popular-collection .collection-box .content .info p span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8AA0;
}

.popular-collection.sell .collection-box .content {
    padding: 0 0 20px;
    align-items: flex-start;
}

.popular-collection.sell .collection-box .images-wrapper img {
    height: auto;
}

.collection-box .images-wrapper .inner-images-wrap {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}

.collection-box .images-wrapper .inner-images-wrap img {
    width: calc(50% - 10px);
}

.collection-box .images-wrapper .inner-images-wrap img:last-child {
    width: 100%;
}

/* Popular Collection End */



/* Top Seller */

.top-seller {
    margin-bottom: 80px;
}

.top-seller-wrapper {
    display: flex;
    margin: -14px;
    flex-wrap: wrap;
}

.slider-wrap .top-seller-wrapper {
    display: block;
    margin: 0 -20px;
}

.slider-wrap .seller-details {
    margin: 0 20px;
    flex-direction: column;
    text-align: center;
    width: auto;
}

.slider-wrap .seller-details .img-wrap {
    margin-bottom: 15px;
}

.slider-wrap .seller-details .img-wrap img {
    width: 120px;
    height: 120px;
}

.slider-wrap .seller-details .seller-info {
    padding: 0;
    width: 100%;
}

.slider-wrap .slick-arrow {
    top: -60px;
    width: 40px;
    height: 40px;
}

.slider-wrap .slick-arrow:hover:before {
    color: #37A9FF;
}

.slider-wrap .slick-arrow:before {
    font-size: 40px;
    opacity: 1;
    transition: all ease-in-out 0.2s;
}

.slider-wrap .slick-arrow.slick-prev {
    left: auto;
    right: 76px;
}

.slider-wrap .slick-arrow.slick-prev:before {}

.slider-wrap .slick-arrow.slick-next {
    right: 20px;
}

.slider-wrap .slick-arrow.slick-next:before {}

.seller-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(20% - 28px);
    margin: 14px;

}

.seller-details .img-wrap {
    position: relative;
}

.seller-details .img-wrap img {
    width: 100px;
    height: 100px;
    border-radius: 30px;
}

.seller-details .img-wrap img.top-seller-mark {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.seller-details .seller-info {
    padding-left: 16px;
    width: calc(100% - 100px);
}

.seller-details .seller-info h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.seller-details .seller-info p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #EBEBEB;
}


/* Top Seller End */



/* Collection Box */

.collection-box {
    background: #343444;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 24px;
}

.collection-box .img-wrap {
    position: relative;
}

.collection-box .img-wrap>img {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;
    height: 250px;
}

.collection-box .timer {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

.timer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #14141F;
    border-radius: 13px;
    padding: 8px 18px 8px 16px;
}

.timer img {
    margin-right: 11px;
}

.timer p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: flex;
}

.timer p span {
    display: inline-block;
    padding: 0 5px;
}

.picks .collection-box .likes {
    position: absolute;
    right: 12px;
    top: 12px;
}

.likes {
    background: #14141F;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
}

.likes img {
    margin-right: 5px;
}

.collection-box .coming-soon {
    position: absolute;
    left: 12px;
    top: 12px;
}

.coming-soon {
    background: #FFBD0C;
    border-radius: 13px;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    color: #343444;
    padding: 4px 19px;
}

.picks .collection-box .img-wrap .common-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.2s;
}

.picks .collection-box:hover .img-wrap .common-btn {
    opacity: 1;
    visibility: visible;
}

.collection-box .common-btn {
    min-width: 150px;
}

.collection-box .common-btn:hover {
    opacity: 0.9;
}

.collection-box .content {
    padding-top: 20px;
}

.collection-box .content h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 17px;
}

.collection-box .content .creator-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-box .content .creator-wrap .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.collection-box .content .left>img {
    width: 44px;
    height: 44px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 12px;
}

.collection-box .content .creator-wrap .creator-info span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8AA0;
    display: block;
    margin-bottom: 2px;
}

.collection-box .content .creator-wrap .creator-info h4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #EBEBEB;
}

.collection-box .content .protocol {
    background: #37A9FF;
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 3px 10px 3px 11px;
}

.collection-box .content .bid-price {
    border-top: 1px solid #14141F;
    padding-top: 15px;
    margin-top: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-box .content .bid-price .left span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8AA0;
    margin-bottom: 2px;
    display: block;
}

.collection-box .content .bid-price .left h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.collection-box .content .bid-price .left h4 span {
    display: inline-block;
}

.collection-box .content .bid-price a {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #8A8AA0;
    display: flex;
    align-items: center;
}

.collection-box .content .bid-price a img {
    margin-right: 8px;
}

.item-detail {
    padding: 80px 0;
}

.item-detail .main-img-wrap {
    max-width: 690px;
    margin: 0 auto 50px;
}

.item-detail .main-img-wrap img {
    width: 100%;
}

.item-detail h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
}

.item-detail .item-interaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.item-detail .item-interaction .item-views {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
}

.item-detail .item-interaction .item-views span {
    background: #343444;
    border-radius: 40px;
    padding: 7px 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 6px;
}

.item-detail .item-interaction .share-option {
    display: flex;
    align-items: center;
    gap: 12px;
}

.item-detail .item-interaction .share-option button {
    border: none;
    background: #343444;
    border-radius: 50px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-detail .authors {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 16px;
}

.item-detail .authors .author-detail {
    background: #343444;
    border-radius: 16px;
    padding: 12px;
}

.item-detail .authors .author-detail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.item-detail .authors .author-detail img {
    border-radius: 15px;
    width: 44px;
    height: 44px;
}

.item-detail .authors .author-detail .detail span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8AA0;
    margin-bottom: 2px;
}

.item-detail .authors .author-detail .detail h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #EBEBEB;
}

.item-detail p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
}

.item-detail .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 32px;
}

.item-detail .price-card {
    padding: 24px;
    background: #343444;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.item-detail .price-card input {
    background: transparent;
    height: 46px;
    border: 1px solid #fff;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    width: 230px;
    outline: 0;
    box-sizing: content-box;
}

.item-detail .price-card h5 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #EBEBEB;
}

.item-detail .price-card span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.item-detail .price-card span b {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.item-detail .bid-btn {
    border: 1px solid #FFFFFF;
    border-radius: 56px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
    width: 100%;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 32px;
}

.item-detail .tabs-wrapper .nav-tabs {
    border-bottom: 1px solid #1F1F2C;
    gap: 50px;
    align-items: center;
    margin-bottom: 8px;
    margin-right: 60px;
}

.item-detail .tabs-wrapper .nav-link {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #fff;
    border: none !important;
    border-bottom: 1px solid transparent !important;
    padding: 0 0 8px;
}

.item-detail .tabs-wrapper .nav-link.active {
    color: #37A9FF;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #37A9FF !important;
}

.item-detail .tabs-wrapper .tab-content {
    height: 210px;
    overflow-y: auto;
    padding-right: 60px;
}

.item-detail .tabs-wrapper .tab-content::-webkit-scrollbar {
    width: 3px;
}

.item-detail .tabs-wrapper .tab-content::-webkit-scrollbar-track {
    background-color: #343444;
}

.item-detail .tabs-wrapper .tab-content::-webkit-scrollbar-thumb {
    background-color: #EBEBEB;
    border-radius: 100px;
}

.item-detail .tabs-wrapper .bid-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 12px;
    position: relative;
}

.item-detail .tabs-wrapper .bid-list li::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 56px);
    height: 1px;
    background-color: #1F1F2C;
}

.item-detail .tabs-wrapper .bid-list li .col-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.item-detail .tabs-wrapper .bid-list li .col-left h5 {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
}

.item-detail .tabs-wrapper .bid-list li .col-left h5 span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.item-detail .tabs-wrapper .bid-list li .col-left .hours {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.item-detail .tabs-wrapper .bid-list li .col-right span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
    display: block;
    text-align: right;
}

.item-detail .tabs-wrapper .bid-list li .col-right span b {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    display: block;
}

.activity {
    padding: 80px 0;
}

.activity .activity-card {
    background: #343444;
    border-radius: 12px;
    padding: 24px 40px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
}

.activity .activity-card .col-left {
    display: flex;
    align-items: center;
    gap: 28px;
}

.activity .activity-card .thumbnail {
    margin: 0 20px;
    flex-direction: column;
    text-align: center;
    width: auto;
}

.activity .activity-card .thumbnail img {
    height: 150px;
    max-width: 100%;
}

.activity .activity-card h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.activity .activity-card h5 span {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #37A9FF;
}

.activity .activity-card p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #EBEBEB;
}

.activity .activity-card p span {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #37A9FF;
}

.activity .activity-card .bullet {
    background: #14141F;
    width: 68px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.activity .load-more-btn {
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    max-width: 154px;
    width: 100%;
    margin: 56px auto 0;
    background-color: transparent;
}

.activity .form-group {
    height: 48px;
    position: relative;
    margin-bottom: 24px;
}

.activity .form-group input {
    width: 100%;
    display: block;
    height: 100%;
    border: 1px solid #343444;
    border-radius: 10px;
    padding: 14px 70px 14px 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #7A798A;
    background-color: transparent;
    outline: none;
}

.activity .form-group button {
    background: #37A9FF;
    border-radius: 0px 10px 10px 0px;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
}

.activity .filter h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.activity .filter ul {
    max-width: 363px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.activity .filter ul li {
    background: #343444;
    border-radius: 40px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0 18px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
}

.activity .filter .clear-filter-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #37A9FF;
    background-color: transparent;
    padding: 0;
    border: none;
}

.blog-detail {
    padding: 80px 0;
}

.blog-detail h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 24px;
    border-bottom: 1px solid #343444;
}

.blog-detail .designer-interview .title-row {
    padding: 16px 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-detail .designer-interview .title-row h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.blog-detail .designer-interview .title-row span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.blog-detail .designer-interview .title-row .col-right ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 87px;
    position: relative;
}

.blog-detail .designer-interview .title-row .col-right ul::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #37A9FF;
    width: 1px;
    height: 36px;
}

.blog-detail .detail-row {
    margin-bottom: 40px;
}

.blog-detail .detail-row h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.blog-detail .detail-row p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #EBEBEB;
    mix-blend-mode: normal;
    margin-bottom: 24px;
}

.blog-detail .detail-row .detail-row-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.blog-detail .social-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #343444;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.blog-detail .social-links h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
}

.blog-detail .social-links .tags,
.blog-detail .social-links .share {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-detail .social-links .tags ul {
    display: flex;
    align-items: center;
}

.blog-detail .social-links .share ul {
    display: flex;
    align-items: center;
    gap: 12px;
}

.blog-detail .social-links .tags ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #EBEBEB;
}

.blog-detail .comment h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.blog-detail .comment form .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
}

.blog-detail .comment form input,
.blog-detail .comment form textarea {
    border: 1px solid #343444;
    border-radius: 4px;
    padding: 14px 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #8A8AA0;
    display: block;
    background-color: transparent;
    width: 100%;
    outline: none;
}

.blog-detail .comment form input::placeholder,
.blog-detail .comment form textarea::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #8A8AA0;
}

.blog-detail .comment form textarea {
    resize: none;
    height: 147px;
}

.blog-detail .comment form button {
    background: #37A9FF;
    border: 1px solid #37A9FF;
    border-radius: 30px;
    padding: 16px 40px;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    margin-top: 36px;
}

.blog-detail .post {
    margin-bottom: 40px;
}

.blog-detail .post ul li {
    background: #343444;
    border-radius: 6px;
    padding: 8px 12px 8px 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}

.blog-detail .post ul li h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.blog-detail .post ul li .content {
    width: calc(100% - 54px);
}

.blog-detail .post ul li .content>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.blog-detail .post ul li p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.blog-detail .post ul li span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8A8AA0;
}

.blog-detail .post h3,
.blog-detail .tag h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.blog-detail .tag ul {
    max-width: 363px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.blog-detail .tag ul li {
    background: #343444;
    border-radius: 40px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #FFFFFF;
}

.help-center .detail {
    text-align: center;
}

.help-center .detail h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 12px;
}

.help-center .detail p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #EBEBEB;
    max-width: 599px;
    width: 100%;
    margin: 0 auto 32px;
}

.help-center .detail .form-group {
    width: 690px;
    margin: 0 auto 40px;
}

.help-center .box {
    background: #343444;
    border-radius: 12px;
    padding: 40px 23px;
    text-align: center;
    margin-bottom: 24px;
}

.help-center .box img {
    margin-bottom: 16px;
}

.help-center .box h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
}

.help-center .box p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #EBEBEB;
}

.signin {
    max-width: 730px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.signin h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: capitalize;
    margin-bottom: 40px;
}

.signin .border-content {
    margin-bottom: 30px;
}

.signin .border-content span {
    position: relative;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    display: block;
}

.signin .border-content span::before,
.signin .border-content span::after {
    content: '';
    display: block;
    max-width: 268px;
    width: 100%;
    height: 1px;
    background-color: #343444;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 1;
}

.signin .border-content span::after {
    left: auto;
    right: 0;
}

.signin .social-buttons {
    margin: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
}

.signin .social-buttons button {
    border: 2px solid #37A9FF;
    background-color: transparent;
    border-radius: 46px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

.signin form input,
.signin form select,
.signin form textarea {
    border: 1px solid #343444 !important;
    border-radius: 8px;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8A8AA0 !important;
    margin-bottom: 24px;
    background-color: transparent !important;
    height: 48px;
}

.signin form select {
    background-color: #14141f !important;
}

.signin form select option {
    color: #8A8AA0 !important;
}

.signin form textarea {
    resize: none;
    height: 120px;
}

.signin form input::placeholder,
.signin form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8A8AA0;
}

.signin form .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    padding: 0;
}

.signin form .form-check {
    max-width: 130px;
    width: 100%;
    margin-bottom: 33px;
}

.signin form .form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 8px;
}

.signin form .submit-btn {
    border: 1px solid #FFFFFF;
    border-radius: 56px;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

.signin form .forget-btn {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    background: none;
    color: #FFFFFF;
    border: none;
}

/* Create Item */

.create-item-wrapper {
    padding: 80px 0;
}

.create-item-wrapper.picks {
    margin: 0;
}

.create-item-wrapper h3,
form label {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
    display: block;
}

form label {
    margin-bottom: 16px;
}

.upload-file {
    position: relative;
    border-radius: 8px;
    border: 1px solid #343444;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-file input {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    opacity: 0;
    top: 0;
}

.upload-file span {
    color: #8A8AA0;
    font-size: 14px;
    line-height: 22px;
}

.create-item-wrapper .signin {
    max-width: 100%;
    padding-left: 50px;
}

form .btn-wrap {
    display: flex;
    justify-content: space-between;
}

form .btn-wrap button {
    width: 32%;
    border-radius: 8px;
    border: 0 !important;
    background-color: #343444;
    color: #8A8AA0;
    font-size: 14px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

form .btn-wrap button img {
    margin-right: 8px;
}

form .btn-wrap button img.purple {
    display: none;
}

form .btn-wrap button:hover,
form .btn-wrap button.active {
    color: #37A9FF;
    background-color: #fff;
    font-weight: 700;
}

form .btn-wrap button:hover img,
form .btn-wrap button.active img {
    display: none;
}

form .btn-wrap button:hover img.purple,
form .btn-wrap button.active img.purple {
    display: inline-block;
}

/* Explore */

.explore {
    padding: 80px 0;
}

.sidebar .accordion-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #fff;
    background-color: transparent;
    border: 0 !important;
}

.sidebar .accordion-item {
    background-color: transparent;
    border: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #343444;
    border-radius: 0;
    margin-bottom: 24px;
}

.sidebar .accordion-header button {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #fff;
    background-color: transparent;
    padding: 0;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.sidebar .accordion-header button:after {
    background-image: url(../../public/images/arrow-down.png);
    background-size: 24px;
}

.sidebar ul {
    padding-top: 12px;
}

.sidebar li input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.sidebar .accordion-collapse {
    border-radius: 0 !important;
}

.sidebar li label {
    position: relative;
    cursor: pointer;
    color: #FFF;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sidebar li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.sidebar li:last-child {
    margin-bottom: 0;
}

.sidebar li label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #7A798A;
    border-radius: 4px;
    background: transparent;
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.sidebar li input:checked+label:before {
    border: 1px solid #37A9FF;
    background: #37A9FF;
}

.sidebar li input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.auctions-main {
    padding: 80px 0;
}

.auctions-main.picks {
    margin: 0;
}

/* Footer */

footer {
    background: #0D0D11;
    padding: 80px 0 25px;
}

footer .inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

footer .footer-about {
    width: 100%;
    max-width: 242px;
    margin-bottom: 50px;
}

footer .footer-about .footer-logo {
    font-weight: 700;
    font-size: 36px;
    text-transform: capitalize;
    color: #FFFFFF !important;
    display: flex;
    align-items: flex-end;
    padding: 0;
    line-height: 1;
    margin-bottom: 20px;
}

/* footer .footer-about .footer-logo img {
    width: 140px;
} */

footer .footer-about p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 28px;
}

footer .social-icons {
    display: flex;
}

footer .social-icons li {
    margin-right: 12px;
}

footer .social-icons li:last-child {
    margin-right: 0;
}

footer .social-icons li a {
    background: #343444;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    transition: all ease-in-out 0.2s;
}

footer .social-icons li a:hover {
    background: #37A9FF;
}
footer .social-icons li a img{
    width: 20px;
}
footer h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 10px 0 24px;
}

footer .links {
    margin-bottom: 50px;
}

footer .links ul li {
    margin-bottom: 16px;
}

footer .links ul li:last-child {
    margin-bottom: 0;
}

footer .links ul li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    transition: all ease-in-out 0.2s;
}

footer .links ul li a:hover {
    color: #37A9FF;
}

footer .subscribe {
    margin-bottom: 50px;
}

footer .subscribe form {
    position: relative;
    width: 100%;
    max-width: 311px;
}

footer .subscribe form input {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8A8AA0;
    border: 1px solid #343444;
    border-radius: 10px;
    width: 100%;
    height: 56px;
    background-color: transparent;
    outline: none;
    padding: 15px 70px 15px 24px;
}

footer .subscribe form button {
    background: #37A9FF;
    border-radius: 0px 10px 10px 0px;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
}

.copyright{
    padding-top: 25px;
    text-align: center;
    border-top: 1px solid #4d4d53;
}

.copyright p{
    color: #9A9DA2;
}

.copyright p img{
    margin-left: 3px;
}

/* User Profile */

.profile {
    margin-top: 160px;
}

.profile .inner {
    background: #313037;
    border-radius: 12px;
    overflow: hidden;
}

.profile .inner .inner-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 30px 0;
    min-height: 300px;
}

.profile .inner .img-wrap {
    width: 270px;
    z-index: 2;

}


.profile .inner .img-wrap img {
    border-radius: 15px;
    object-fit: cover;
    width: 100%;
    height: 270px;
}

.profile .right {
    width: calc(100% - 270px);
    padding-left: 35px;
}

.profile .right div {
    height: 100%;
}

.profile .inner .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 35px;
    align-items: flex-start;
}

.profile .inner .content h2 {
    margin-bottom: 12px;
}

.profile .inner .content p {
    margin-bottom: 24px;
}

.wallet-address {
    border-radius: 50px;
    background: #EBEBEB;
    padding: 8px 20px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}

.wallet-address:hover {
    opacity: 0.9;
}

.wallet-address span {
    color: #7A798A;
    font-size: 13px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: calc(100% - 30px);
}

.wallet-address img {
    width: 16px;
}

.profile .right .wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: auto;
}

.profile .social-icons {
    display: flex;
}

.profile .social-icons li {
    margin-right: 12px;
}

.profile .social-icons li:last-child {
    margin-right: 0;
}

.profile .social-icons li a {
    background: #fff;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    transition: all ease-in-out 0.2s;
}

.profile .social-icons li a:hover {
    background: #37A9FF;
}

.profile .wrap .common-btn {
    font-size: 14px;
    padding: 9px 24px;
    margin-left: 32px;
}

.profile .profile-bottom {
    background: #343444;
    min-height: 74px;
    margin-top: -30px;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.profile .profile-bottom nav {
    width: calc(100% - 300px);
    padding-left: 35px;
}

.profile .profile-bottom nav .nav-tabs {
    border: 0;
    padding-top: 24px;
    justify-content: space-around;
}

.profile .profile-bottom nav .nav-tabs button {
    border: 0;
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.profile .profile-bottom nav .nav-tabs button:before {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: '';
    bottom: -23px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 10px solid #14141f;
    opacity: 0;
    transition: all ease-in-out 0.2s;
}

.profile .profile-bottom nav .nav-tabs button:hover:before {
    opacity: 1;
}

.profile .profile-bottom nav .nav-tabs button.active:before {
    opacity: 1;
}

.profile .tab-content {
    margin-top: 60px;
}

.profile .tab-content .filters-wrap {
    margin-bottom: 32px;
}

.profile .tab-content .filters-wrap form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile .tab-content .filters-wrap form .left {
    display: flex;
    align-items: center;
}

.profile .tab-content .filters-wrap form .left select {
    display: flex;
    align-items: center;
    background: #343444;
    border: 0 !important;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    background-image: url(../../public/images/arrow-down.png);
    background-position: calc(100% - 5px);
    background-repeat: no-repeat;
    padding: 11px 50px 11px 15px;
    box-shadow: none !important;
    height: 45px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper {
    background: #343444;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    height: 45px;
    margin-left: 12px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img {
    margin-right: 16px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img:last-child {
    margin-right: 0;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper span {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    margin-right: 16px;
}


.profile .tab-content .filters-wrap form .time {
    background: #343444;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    height: 45px;
}

.profile .tab-content .filters-wrap form .time span {
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    margin-right: 16px;
    opacity: 0.5;
}

.profile .tab-content .filters-wrap form .time span.active {
    opacity: 1;
}

.profile .tab-content .table-wrap {
    overflow: auto;
    margin-bottom: 70px;
}

.profile .tab-content table {
    width: 100%;
    min-width: 920px;
}


.profile .tab-content table th,
.profile .tab-content table td {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding: 12px 0;
}

.profile .tab-content table tr {
    border-bottom: 1px solid #1F1F2C;
}

.profile .tab-content table tbody tr:last-child {
    border-bottom: 0;
}

.profile .tab-content table td.percentage {
    color: #47A432;
}

.profile .tab-content table tr.loss td.percentage {
    color: #DF4949;
}

.profile .tab-content table th img {
    margin-left: 7px;
}

.profile .tab-content table td .img-wrap {
    position: relative;
    width: 56px;
    height: 56px;
    display: inline-block;
    margin-right: 16px;
}

.profile .tab-content table td .img-wrap img {
    border-radius: 15px;
    object-fit: cover;
    width: 56px;
    height: 56px;
}

.profile .tab-content table td .img-wrap img.top-seller-mark {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.profile .tab-content .signin {
    max-width: 100%;
    text-align: left;
}

.profile .tab-content .create h2 {
    margin-bottom: 12px;
}

.profile .tab-content .create p {
    margin-bottom: 24px;
}

.profile .tab-content .create .form-group {
    margin-bottom: 48px;
}

.profile .tab-content .create .form-group>span {
    color: #8A8AA0;
    font-size: 14px;
    margin-top: 12px;
    display: block;
}

.profile .tab-content .create .form-group input,
.profile .tab-content .create .form-group select,
.profile .tab-content .create .form-group textarea,
.profile .tab-content .create .form-group .upload-file {

    max-width: 690px;
}

form p a {
    color: #37A9FF;
}

.profile .tab-content .create .choose {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
}

.profile .tab-content .create .choose .box {
    width: 49%;
    display: flex;
    align-items: center;
    padding: 24px;
    border: 1px solid #343444;
    border-radius: 8px;
}

.profile .tab-content .create .choose .box.active {
    background: #343444;
}

.profile .tab-content .create .choose .box img {
    width: 120px;
    border-radius: 8px;
    object-fit: cover;
}

.profile .tab-content .create .choose .box .info {
    width: calc(100% - 120px);
    padding-left: 32px;
}

.profile .tab-content .create .choose .box .info h3 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 16px;
}

.profile .tab-content .create .choose .box .info p {
    margin-bottom: 0;
    color: #8A8AA0;
    font-size: 14px;
}

.profile .tab-content .create .common-btn.create-btn {
    background: #37A9FF;
    width: 183px;
    height: 54px;
    border-radius: 100px;
}

.profile .tab-content .create .common-btn.create-btn:hover {
    background: transparent;
    border-color: #37A9FF;
}

.profile .tab-content .create {
    margin-bottom: 80px;
}

.profile .tab-content .create .check-box {
    width: 52px;
    height: 26px;
    background: #EBEBEB;
    border-radius: 50px;
}

.profile .tab-content .create input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 52px;
    height: 26px;
    background: #EBEBEB;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
    margin: 0 !important;
    border: 0 !important;
}

.profile .tab-content .create input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    top: 4px;
    left: 6px;
    background: #37A9FF;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
}

.profile .tab-content .create input:checked[type="checkbox"]::after {
    left: 50%;
}

.profile .tab-content .create .form-group .label-head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
    max-width: 690px;
}

.profile .tab-content .create .form-group .label-head label {
    display: flex;
    align-items: center;
    margin: 0;
}

.profile.collection .profile-bottom nav {
    display: flex;
    align-items: center;
    padding: 0 35px;
}

.minting-btn {
    border-radius: 50px;
    background: #EBEBEB;
    border-radius: 100px;
    color: #7A798A;
    font-size: 14px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}

.minting-btn:hover {
    opacity: 0.9;
}

.minting-btn span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #47A432;
    margin-right: 6px;
}

.profile .profile-bottom nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.profile .profile-bottom nav ul li p {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
}

.profile .profile-bottom nav ul li span {
    color: #EBEBEB;
    font-size: 14px;
    font-weight: 400;
}

.collection-info-wrapper .activity {
    padding: 0;
    display: flex;
    width: 550px;
}

.collection-info-wrapper .activity .form-group:first-child {
    width: 400px;
    margin-right: 48px;
}

.collection-info-wrapper .activity .form-group {
    margin: 0;
}

.collection-info-wrapper .activity .form-group select {
    border-radius: 6px;
    background: #343444;
    border-radius: 6px;
    color: #fff;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    padding: 12px 36px 12px 16px;
    -webkit-appearance: none;
    background-image: url(../../public/images/arrow-down.png);
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
    background-size: 20px;
}

.collection-info-wrapper .activity .form-group select option {
    font-size: 13px;
    font-weight: 400;
}

.collection-info-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 60px 0;
}

.collection-info-wrapper .tags-wrapper {
    display: flex;
    align-items: center;
}

.collection-info-wrapper .tags-wrapper .tag {
    margin-right: 12px;
    padding: 11px 16px;
    border-radius: 6px;
    background: #343444;
}

.collection-info-wrapper .tags-wrapper .tag p {
    color: #85858F;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
}

.collection-info-wrapper .tags-wrapper .tag p b {
    color: #fff;
    font-weight: 600;
}

.edit-profile {
    margin: 160px 0 80px;
}

.signin.edit-profile {
    max-width: initial;
    width: initial;
    text-align: left;
}

.signin.edit-profile .row {
    justify-content: space-between;
}

.signin.edit-profile h2 {
    margin-bottom: 12px;
}

.signin.edit-profile p {
    margin-bottom: 40px;
}

.edit-collection form.img-wrap,
.signin.edit-profile form.img-wrap {
    position: relative;
}

.edit-collection form.img-wrap input,
.signin.edit-profile form.img-wrap input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    top: 0;
    left: 0;
}

.edit-collection form.img-wrap img,
.signin.edit-profile form.img-wrap img {
    width: 100%;
}

.edit-collection form.img-wrap img.edit-icon,
.signin.edit-profile form.img-wrap img.edit-icon {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: auto;
    height: auto;
}

.edit-collection .signin {
    margin: 80px 0;
    max-width: 690px;
}

.edit-collection .inner-wrap {
    position: relative;
}

.edit-collection .inner-wrap .background-img-wrap {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 40px;
    right: 20px;
    width: auto;
    z-index: 1;
}

.edit-collection .inner-wrap .background-img-wrap input {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.profile .inner-wrap .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.minting-collection ul {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 15px;
}

.minting-collection ul {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 15px;
}

.minting-collection ul li {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}

.minting-collection .see-more {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    text-decoration: underline;
    margin-bottom: 40px;
    transition: all ease-in-out 0.2s;
}

.minting-collection .see-more:hover {
    opacity: 0.8;
}

.minting-collection .progress-bar-wrap {
    margin-bottom: 35px;
}

.minting-collection .progress-bar div {
    height: 8px !important;
    border-radius: 8px !important;
}

.minting-collection .progress-bar div span {
    display: none !important;
}

.minting-collection .progress-bar>div {
    background: #343444 !important;
}

.minting-collection .progress-bar>div>div {
    background: #37A9FF !important;
}

.minting-collection .progress-bar-wrap .progressbar-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
}

.minting-collection .progress-bar-wrap .progressbar-head span {
    color: #EBEBEB;
    font-size: 15px;
    font-weight: 600;
}

.minting-collection .progress-bar-wrap .progressbar-head span b {
    font-weight: bold;
    color: #fff;
    font-size: 18px;
}

.public-stage-wrapper {
    margin-bottom: 32px;
}

.public-stage-wrapper h3 {
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 20px;
}

.public-stage-wrapper .inner {
    border-radius: 8px;
    background: #343444;
    padding: 24px;
}

.public-stage-wrapper .inner .limit-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.public-stage-wrapper .inner .limit-wrap p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1.4px;
}

.public-stage-wrapper .inner .limit-wrap span {
    color: #8A8AA0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.public-stage-wrapper .inner .limit-wrap .common-btn {
    color: #37A9FF;
    width: 153px;
}

.public-stage-wrapper .inner .limit-wrap .common-btn:hover {
    color: #fff;
}

.public-stage-wrapper .inner .limit-wrap .form-group {
    position: relative;
}

.public-stage-wrapper .inner .limit-wrap .form-group input {
    background-color: transparent;
    color: #8A8AA0;
    font-size: 14px;
    font-weight: 400;
    border-radius: 50px;
    height: 46px;
    text-align: center;
    padding: 10px 60px;
    width: 230px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.public-stage-wrapper .inner .limit-wrap .form-group input::-webkit-outer-spin-button,
.public-stage-wrapper .inner .limit-wrap .form-group input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.public-stage-wrapper .inner .limit-wrap .form-group img {
    position: absolute;
    top: 3px;
    width: 40px;
    height: 40px;
    left: 3px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}

.public-stage-wrapper .inner .limit-wrap .form-group img:hover {
    opacity: 0.85;
}

.public-stage-wrapper .inner .limit-wrap .form-group img.increment {
    left: auto;
    right: 3px;
}

.public-stage-wrapper .inner>.common-btn {
    width: 100%;
    background: #37A9FF;
}

.public-stage-wrapper .inner>.common-btn:hover {
    background: #000;
    border: 1px solid #000;
}

.common-modal {
    background: rgba(20, 20, 31, 0.70);
    max-width: 100%;
}

.common-modal .modal-content {
    background-color: #32323f;
}

.common-modal .btn-close {
    filter: invert(1);
}

.common-modal .modal-header {
    border: 0;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 22px;
}

.common-modal.signin form input {
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    color: #fff !important;
    font-size: 16px !important;
}

.common-modal form {
    padding: 20px 30px 30px;
}

.common-modal form .common-btn {
    background: #37A9FF;
    font-size: 16px;
}

.common-modal form .common-btn:hover {
    background: #fff;
    color: #37A9FF;
}

.common-modal .modal-dialog {
    top: 50%;
    transform: translateY(-50%) !important;
    margin: 0 auto;
    width: 90%;
}

.common-modal input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.public-collection{
    margin: 80px 0;
}

/* .button {
    background: #FFFFFF;
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 17px;
    max-width: 192px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #254C50;
    transition: all ease-in-out 0.2s;
}

.button:hover {
    opacity: 0.95;
    transform: translateY(-1px);
    color: #254C50;
} */

/* global css end */

/* header css start */
@media (min-width: 1200px) {
    .container {
        max-width: 1450px;
    }
}

@media screen and (max-width:1439px) {
    .collection-info-wrapper .tags-wrapper {
        margin-right: -12px;
    }

    .collection-info-wrapper {
        flex-direction: column;
    }

    .collection-info-wrapper .tags-wrapper .tag {
        flex-grow: 1;
        text-align: center;
        margin-bottom: 15px;
    }

    .collection-info-wrapper .activity {
        width: 100%;
        justify-content: center;
    }

    .collection-info-wrapper .activity .form-group:first-child {
        margin-right: 12px;
    }
}

@media screen and (max-width:1365px) {
    .profile .right .wrap {
        flex-direction: column;
        align-items: flex-end;
    }

    .profile .wrap .common-btn {
        margin-top: 20px;
    }
}

@media screen and (max-width:1199px) {
    .public-stage-wrapper .inner .limit-wrap {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .profile .profile-bottom nav ul {
        flex-wrap: wrap;
    }

    .seller-details {
        width: calc(25% - 28px);
    }

    header .navbar-collapse {
        position: absolute;
        top: 64px;
        background: linear-gradient(216.56deg, rgb(226, 80, 229) 5.32%, rgb(75, 80, 230) 94.32%);
        left: 0;
        width: 100%;
    }

    header .navbar-nav li {
        margin: 10px 20px;
    }

    header .navbar-nav {
        padding: 10px;
    }
}

@media screen and (max-width:991px) {
    .explore .sidebar {
        margin-bottom: 50px;
    }

    .public-stage-wrapper .inner .limit-wrap {
        flex-direction: row;
        gap: 0;
        text-align: left;
    }

    .edit-collection .inner-wrap .background-img-wrap {
        bottom: 15px;
    }

    .collection-info-wrapper .tags-wrapper {
        flex-wrap: wrap;
    }

    .profile .tab-content .create .choose {
        flex-direction: column;
    }

    .profile .tab-content .create .choose .box {
        width: 100%;
        margin-bottom: 24px;
    }

    .profile .tab-content .create .choose .box:last-child {
        margin-bottom: 0;
    }

    .profile .tab-content .filters-wrap form {
        flex-direction: column;
        align-items: flex-start;

    }

    .profile .tab-content .filters-wrap form .time {
        margin-top: 12px;
    }

    .profile .right div {
        height: auto;
    }

    .seller-details {
        width: calc(33.33% - 28px);
    }

    .profile .profile-bottom {
        margin-top: 0;
    }

    .profile .profile-bottom nav {
        width: 100%;
        padding-left: 0;
    }

    footer .inner {
        flex-wrap: wrap;
    }

    .profile .inner .img-wrap {
        width: 200px;
    }

    .profile .inner .img-wrap img {
        height: 200px;
    }

    .profile .inner .inner-wrap {
        padding: 20px;
    }

    .profile .right {
        width: calc(100% - 200px);
    }

    .profile .right .wrap {
        flex-direction: row;
        justify-content: flex-start;
    }

    .profile .wrap .common-btn {
        margin-top: 0;
    }

    .profile .right {
        width: 100%;
        padding: 0;
        padding-top: 20px;
    }

    footer .footer-about {
        order: 1;
        width: 50%;
    }

    footer .account {
        order: 3;
    }

    footer .resources {
        order: 4;
    }

    footer .company {
        order: 5;
    }

    footer .subscribe {
        order: 2;
        width: 49%;
    }
}

@media screen and (max-width:767px) {
    .collection-box .img-wrap>img {
        height: auto;
    }

    .public-stage-wrapper .inner .limit-wrap {
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }

    .profile.edit-collection .inner .img-wrap {
        max-width: 270px;
        margin: 0 auto;
    }

    .profile.edit-collection .inner .inner-wrap {
        padding: 20px 20px 90px;
    }

    .signin.edit-profile form.img-wrap {
        margin-bottom: 40px;
    }

    .profile .profile-bottom nav ul li {
        width: 33.33%;
        margin: 15px 0;
    }

    .profile .inner .inner-wrap {
        flex-direction: column;
    }

    .upload-file {
        padding: 20px;
    }

    .profile .tab-content .filters-wrap form .left {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile .tab-content .filters-wrap form .left .icons-wrapper {
        margin-left: 0;
        margin-top: 12px;
    }

    .profile .profile-bottom nav .nav-tabs {
        justify-content: flex-start;
    }

    .profile .inner .img-wrap {
        width: 100%;
    }

    .profile .inner .img-wrap img {
        height: auto;
    }

    .seller-details {
        width: calc(50% - 28px);
    }

    .auctions .auction-slider-wrapper {
        margin: 0;
    }

    .item-detail .price {
        flex-wrap: wrap;
        gap: 20px;
    }

    .item-detail .price .price-card {
        width: 100%;
    }

    .item-detail .authors {
        gap: 20px;
        grid-template-columns: 1fr;
    }

    .item-detail .tabs-wrapper .tab-content {
        padding-right: 5px;
    }

    .item-detail .tabs-wrapper .nav-tabs {
        margin-right: 0;
    }

    .signin .border-content span::before,
    .signin .border-content span::after {
        display: none;
    }

    .profile .profile-bottom nav .nav-tabs {
        flex-wrap: wrap;
        padding: 15px 0;
    }

    .profile .profile-bottom nav .nav-tabs button {
        width: 50%;
        margin: 5px 0 !important;
    }

    .profile .profile-bottom nav .nav-tabs button:before {
        display: none;
    }

    .profile .profile-bottom nav .nav-tabs button:hover,
    .profile .profile-bottom nav .nav-tabs button.active {
        color: #37A9FF;
    }
}

@media screen and (max-width:575px) {

    .item-detail .price-card {
        flex-direction: column;
    }

    .profile .profile-bottom nav ul li {
        width: 50%;
    }

    .profile .tab-content .create .choose .box {
        padding: 20px;
    }

    .profile .tab-content .create .choose .box .info h3 {
        margin-bottom: 10px;
    }

    .profile .tab-content .create .choose .box img {
        width: 100px;
    }

    .profile .tab-content .create .choose .box .info {
        width: calc(100% - 100px);
        padding-left: 24px;
    }

    .profile .right .wrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile .wrap .common-btn {
        margin-top: 20px;
        margin-left: 0;
    }

    .banner h1 {
        font-size: 41px;
    }

    h2 {
        font-size: 30px;
    }

    .banner {
        padding: 140px 0 30px;
    }

    header .right .common-btn {
        padding: 8px 10px;
    }

    footer .footer-about {
        width: 100%;
        max-width: 100%;
    }

    footer .account {
        width: 100%;
    }

    footer .resources {
        width: 100%;
    }

    footer .company {
        width: 100%;
    }

    footer .subscribe {
        width: 100%;
    }

    .seller-details {
        width: 100%;
    }

    header .navbar-collapse {
        top: 57px;
        left: -20px;
        width: calc(100% + 40px);
    }
}